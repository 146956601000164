import React, { useEffect } from 'react';

import { Grid } from '@mui/material';

import {useIntl} from 'gatsby-plugin-intl';
import { navigate } from 'gatsby-plugin-intl';

import {getUser} from 'services/api/requests';
import {getHome} from 'services/api/newsService';
import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import WelcomeMessage from 'components/common/WelcomeMessage';
import NewsList from 'components/news/NewsList';
import SidePanel from 'components/user/SidePanel';

const HomePage = () => {

    const intl = useIntl();
    const initialUser = {
        cvcUser: {
            courses: []
        },
        samlUserDetails: {}
    }
    let [user, setUser] = React.useState(initialUser);
    let [home, setHome] = React.useState({});

    useEffect(() => {
        let mounted = true;
        async function fetchData() {
            try {
                let user = await getUser();
                if(mounted) setUser(user.data);
                let home = await getHome();
                if(mounted) setHome(home.data);
            } catch (error) {
                console.error("error fetching user & home page datas", error);
            }
        }
        fetchData();
        return () => {
            mounted = false;
        }
    }, [setHome, setUser]);

    const styles = {
        screenHeight: "59vh"
    }

    const handleSessionClick = (courseId, sessionId) => {
        navigate("/courses/" + courseId + "/session/" + sessionId);
    }

    return (
        <Layout>
            <Seo
                title={intl.formatMessage({ id: "title" })}
                lang={intl.locale}
            />
            <Grid container>
                <WelcomeMessage user={user} />
                <Grid container spacing={2} sx={{ marginTop:"5px", marginBottom:"5px" }} alignItems="stretch">
                    <Grid item xs={12} lg={4}>
                        <NewsList user={user} height={styles.screenHeight} />
                    </Grid>
                    <Grid item xs={12} lg={8} >
                        {   user && 
                                <SidePanel 
                                    userId={user?.cvcUser?.userId}  
                                    sessions={user?.cvcUser?.sessions} 
                                    courses={user?.cvcUser?.courses}
                                    news={home}
                                    onSessionClick={handleSessionClick}
                                    height={styles.screenHeight}
                                />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Layout>
    )
}

export default HomePage;
