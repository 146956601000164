import { Stack } from "@mui/material";
import Seo from "components/common/Seo";
import Layout from "components/layout/Layout";
import { useIntl } from "gatsby-plugin-intl";
import React, { useCallback, useEffect, useState } from "react";
import academicService from "services/api/academicService";
import AcademicDetail from "./components/AcademicDetail";
import BackButton from "ui/BackButton";

const AcademicDetailPage = ({ academicId }) => {

    const intl = useIntl();
    const [academic, setAcademic] = useState();
    const [title, setTitle] = useState('');

    const loadServerRows = useCallback(async () => {
        const data = await academicService.searchAcademicById(academicId);
        let academicData = data.data;
        setAcademic(academicData);
        let seoTitle = intl.formatMessage({ id: "academic.seo.title" });
        if (academic && academic.firstName && academic.lastName) {
            setTitle(seoTitle + ' ' + academic.firstName + ' ' + academic.lastName);
        }        
    }, []);

    useEffect(() => {
        loadServerRows();        
    }, []);
    
    return (
        <Layout>
            <Seo title={title} lang={intl.locale} />
            <Stack spacing={2}>
                <BackButton />
                <AcademicDetail academic={academic} />
            </Stack>
        </Layout>
    );
};

export default AcademicDetailPage;
