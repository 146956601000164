import React, { useEffect, useState } from 'react';

import { useIntl } from 'gatsby-plugin-intl';
import { navigate } from 'gatsby';

import Layout from 'components/layout/Layout';
import Seo from 'components/common/Seo';
import courseService from 'services/api/courseService';
import CourseInfo from 'components/catalog/course/CourseInfo';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import BackButton from 'ui/BackButton';
import { getUser } from "services/api/requests";


const CourseDetailPage = ({ courseId, sessionId }) => {
    const intl = useIntl();
    const [title, setTitle] = useState(intl.formatMessage({id: "course.catalog"}));
    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(null);
    const [courseDetails, setCourseDetails] = useState(null);

    useEffect(() => {
        let mounted = true;
        const fetchData = async () => {
            setLoading(true);
            try {
                const userResponse = await getUser();
                const courseResponse = await courseService.searchPublicInfoById(courseId);                
                if(mounted){
                    setUser(userResponse?.data);                
                    setCourseDetails(courseResponse?.data);
                    setTitle(courseResponse?.data?.title);                
                }                
            } catch (error) {
                console.error("error fetching user & course datas ", error);                
            } finally {
                if(mounted) setLoading(false);
            }

        };

        if (!!courseId) {            
            fetchData();
        }

        return () => { mounted=false; };
    }, []);

    return (
        <Layout inverseBackground={true}>
            <Seo
                title={title}
                lang={intl.locale}
            />
            <BackButton/>
            { loading ?
                <Box justifyContent={'center'} display={'flex'}>
                    <CircularProgress />
                </Box>
                :
                <CourseInfo 
                    courseId={courseId} 
                    sessionId={sessionId}
                    user={user}
                    courseDetails={courseDetails}
                />
            }             
        </Layout>
    );
};

export default CourseDetailPage;

